<template>
  <!-- NAME[epic=组件] 限定业务人员 -->
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="1100px"
    top="5vh"
  >
    <div v-loading="loading" class="wrapper">
      <div class="content">
        <div class="tree">
          <el-tree
            :data="treeData"
            :props="defaultProps"
            node-key="id"
            :default-expanded-keys="[1]"
            highlight-current
            style="height: 500px; overflow: auto"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
        <div class="leftTable">
          <el-table
            v-loading="lTableLoading"
            stripe
            :data="lTableData"
            border
            height="480"
            @selection-change="lTableClick"
          >
            <!-- 选择框 -->
            <el-table-column
              align="center"
              type="selection"
              width="50"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="user_name"
              label="业务员姓名"
              width="auto"
            ></el-table-column>
          </el-table>
          <el-button
            style="float: right; margin-top: 5px"
            type="primary"
            @click="add"
          >
            添加
          </el-button>
        </div>
        <div class="middleImg">
          <i
            class="el-icon-caret-right"
            style="cursor: pointer"
            @click="add"
          ></i>
        </div>
        <div class="rightTable">
          <el-table
            v-loading="rTableLoading"
            stripe
            :data="rTableData"
            border
            height="480"
            @selection-change="rTableClick"
          >
            <el-table-column
              align="center"
              prop="depart_name"
              label="部门"
              width="auto"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="user_name"
              label="业务员姓名"
              width="110"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="mobile"
              label="电话"
              width="110"
            ></el-table-column>
            <el-table-column align="center" prop="" label="操作" width="60">
              <template #default="{ $index }">
                <el-button type="text" @click="handleDelete($index)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button v-show="saveshow == 1" type="primary" @click="save">
        保存
      </el-button>
      <el-button v-show="saveshow == 2" type="primary" @click="save3">
        保存
      </el-button>
      <el-button v-show="saveshow == 3" type="primary" @click="saveAll">
        保存
      </el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { getTree, getUserData } from '@/api/setPrice'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'SetComponent',
    components: {},
    props: {
      id: {
        type: Number,
        default: () => 0,
      },
      opationkey: {
        type: String,
        default: () => '',
      },
      title: {
        type: String,
        default: () => '指定部门员工',
      },
      isCreate: {
        type: Boolean,
        default: () => false,
      },
      chenlie: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        saveshow: 1,
        showDialog: false,
        loading: false,
        lTableLoading: false,
        rTableLoading: false,
        tableRowSelect: [],
        treeData: [],
        lTableData: [],
        rTableData: [],
        defaultProps: {
          children: 'children',
          label: 'depart_name',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          console.log(this.id, '什么id')
          this.fetchData()
        } else {
          this.saveshow = 1
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 树 数据获取
      async fetchData() {
        let { data, msg, code } = await getTree({ scheme_id: this.id })
        console.log(data)
        this.treeData = data.depart
        if (!this.isCreate) {
          this.rTableData = data.list.map(list=>{
            list.user_id = list.id
            return list
          })

          if (this.saveshow == 2) {
            this.handlerdatalist()
          }
        }
        this.loading = false
      },
      // 左边表格数据获取
      async getlTable(id) {
        this.lTableLoading = true
        let { data, msg, code } = await getUserData({ depart_id: id })
        console.log(data, code)
        if (code == 200) {
          this.lTableData = data
        } else {
          this.$message.error(msg)
        }
        this.lTableLoading = false
      },
      // 根据userid查user详情添加到右边表格
      getUserList(userids) {
        console.log('getUserList根据userid查user详情添加到右边表格')
        if (userids) {
          console.log('uer', userids)
          if (this.chenlie) {
            // 公用用baseAdmin/common/user-list
            let url = '/baseAdmin/common/user-list'
            let data = {
              limit_user: userids.join(),
              pageSize: -1,
            }
            postAction(url, data).then((r) => {
              this.rTableData = null
              this.rTableData = r.data
              console.log('获取succ', this.rTableData)

              // this.rTableData.forEach((i) => {
              //   i.depart_name = i.depart
              // })
            })
          } else {
            let url = '/promoteAdmin/prize/user-rel-list'
            let data = {
              id: userids,
              pageSize: -1,
            }
            postAction(url, data).then((r) => {
              this.rTableData = r.data
              this.rTableData.forEach((i) => {
                i.depart_name = i.depart
              })
            })
          }
        }
      },
      // 树形节点点击事件
      handleNodeClick(val) {
        this.getlTable(val.id)
      },
      // 左右表格 row 点击事件
      lTableClick(val) {
        console.log(val)
        this.tableRowSelect = val.map((list) => {
          list.user_id = list.id
          return list
        })
      },
      rTableClick(val) {
        console.log(val)
      },
      add() {
        let select = this.tableRowSelect
        console.log(select)
        if (select.length == 0) {
          this.$message.error('请选择业务员')
          return false
        }
        // this.rTableData.forEach((item) => {
        //   if (item.id) {
        //     item.user_id = item.id
        //   } else if (item.user_id) {
        //     item.id = item.user_id
        //   }
        // })
        select.forEach((item) => {
          // 先判断存不存在相同
          let haveed = this.rTableData.filter(
            (rTdItem) => rTdItem.user_id == item.user_id
          )
          console.log(haveed)
          if (haveed.length !== 0) {
            try {
              haveed.forEach((item1) => {
                this.$message.error('业务员 ' + item1.user_name + ' 已存在')
                throw new Error('存在相同业务员')
                // return false
              })
            } catch (e) {
              if (e.message == '存在相同业务员') {
                // throw e
                return false
                console.log(e)
              }
            }
          } else {
            this.rTableData.push(item)
            this.$message.success('添加成功')
          }
        })
      },
      // 删除
      handleDelete(index) {
        console.log('删除', index)
        this.rTableData.splice(index, 1)
      },
      // 删除全部
      handleDeleteAll() {
        console.log('ssssssssss')
        this.rTableData.forEach((i, idx) => {
          this.rTableData.splice(idx, 1)
        })
      },
      saveAll() {
        let data = this.rTableData
        let userArr = []
        data.forEach((item) => {
          // if (item.id) {
          //   item.user_id = item.id
          // } else if (item.user_id) {
          //   item.id = item.user_id
          // }
          userArr.push(item.user_id)
        })
        let endData = userArr
        console.log(endData)
        this.$emit('set-userid', endData, this.opationkey)
        this.$emit('r-table-data', this.rTableData)
        this.showDialog = false
      },
      save() {
        let data = this.rTableData
        let userArr = []
        data.forEach((item) => {
          // userArr.push(item.id)
          userArr.push(item.user_id)
        })
        let endData = userArr.join(',')
        console.log(endData)
        this.$emit('set-userid', endData)
        this.$emit('r-table-data', this.rTableData)

        this.$message.success('保存成功')
        this.showDialog = false
      },
      handlerdatalist() {
        postAction('/setAdmin/config/user-rel-list', {})
          .then((res) => {
            console.log(res, 'yognhu')
            var userdata = []
            res.data.forEach((list) => {
              let data = {
                depart_name: list.depart,
                id: list.user_id,
                user_id: list.user_id,
                mobile: list.mobile,
                user_name: list.user_name,
                wechat_name: '',
              }
              userdata.push(data)
            })
            this.rTableData = userdata
          })
          .catch((err) => {
            console.log(err)
          })
      },
      save3() {
        let data = JSON.parse(JSON.stringify(this.rTableData))
        let arr = data.map((list) => {
          // return list.id
          return list.user_id
        })
        console.log(arr, 'hhhhhhhhhhhhhhh')
        postAction('/setAdmin/config/user-set', { user: JSON.stringify(arr) })
          .then((res) => {
            console.log(res)
            this.$message.success(res.msg)
            this.showDialog = false
            this.$emit('getlist')
          })
          .catch((err) => {
            console.log(err)
          })
      },

      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    min-height: 500px;
  }
  .content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .tree {
    width: 25%;
  }
  .leftTable {
    box-sizing: border-box;
    width: 25%;
    padding: 0 10px;
  }
  .middleImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 5%;
    text-align: center;
    i {
      width: 100%;
      font-size: 50px;
      color: #000;
    }
  }
  .rightTable {
    width: 45%;
    padding: 0 10px;
  }
</style>
